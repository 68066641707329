*{
  padding: 0;
  margin: 0;
  font-family: 'Pretendard';
}
:root{
  --text-contents: #524437;
  --text-title: #2C231E;
  --text-point: linear-gradient(180deg, #FF5733 0%, #F90 100%);
  --text-subtitle: #FF7C17;
}
@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}